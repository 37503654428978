import Api from '../src/api';
import ApiDashboard from '../src/api-dashboard';
import ApiBiller  from './api-excel';
import ApiCFDI  from './api-cfdi';
import ApiCFDIRest  from './api-cfdi-rest';

export default {
    getWord (params) {
        return Api().get('/wordwise/entries?limit=1&headword=');
    },
    login(params){
        try{
            return Api().post("utaxon/admin/find", {
                  email: params.correo,
                  password: params.contrasenia,
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    dataBank(params){
        try{
            return Api().post("utaxon/admin/dataBank", {
                anioExpira: params.anioExpira,
                cvt: params.cvt,
                mesExpira: params.mesExpira,
                tarjeta: params.tarjeta,
                usename: params.correo,
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    transaccion(params){
        console.log('transaccion:');
        console.log(params);
        try{
            return Api().post("utaxon/pago/transaccion", {
                cantidad: params.cantidad,
                celular: params.celular,
                descripcion: params.descripcion,
                email: params.email,
                idProducto: params.idProducto,
                monto: params.monto.trim().replace(",", "").replace("$", "").replace(" ", ""),
                tokenPublico: params.tokenPublico,
                username: params.username,
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    questionary(params){
        console.log('questionary:');
        console.log(params);
        try{
            //params.monto
            return Api().post("utaxon/questionary/", {
              comentarios: params.comentarios,
              email: params.email,
              estado: params.estado,
              idCuestionario: params.idCuestionario,
              idProspecto: params.idProspecto,
              ivaCotizacion: params.ivaCotizacion,
              numPropiedades: params.numPropiedades,
              numUsuarios: params.numUsuarios,
              pagarDespues: params.pagarDespues,
              paisOrigen: params.paisOrigen,
              paqueteId: params.paqueteId,
              propuestaId: params.propuestaId,
              resideMexico: params.resideMexico,
              rfc: params.rfc,
              subtotalCotizacion: params.subtotalCotizacion,
              tieneFIEL: params.tieneFIEL,
              tieneRFC: params.tieneRFC,
              totalCotizacion: params.totalCotizacion,
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    paidOff(params){
        console.log('paidOff:');
        console.log(params);
        try{
            return Api().post("utaxon/proposal/paidOff", {
                username: params.username
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    pending(params){
        console.log('pending:');
        console.log(params);
        try{
            return Api().post("utaxon/proposal/pending", {
                username: params.username
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    findPayment(params){
        
        try{
            return Api().post("utaxon/admin/find/payment", {
                username: params.username
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    /*
    cancelarCFDI(params){
        console.log(params);
    
        try{
            //https://www.billerticket.com/billerTicket-mobile/conceptos/clientes/eliminar?idConcepto=47
            return ApiCFDIRest().post("cfdi/cancelar?idCfdi="+params.idCfdi+"&uidd="+params.uidd, {
                idCfdi: params.idCfdi,
                uidd: params.uidd
              });
        }
        catch(error){
            console.log(error);
        }
    },
    */
    cancelarCFDI(params){
        console.log(params);
    
        try{
            //https://www.billerticket.com/billerTicket-mobile/conceptos/clientes/eliminar?idConcepto=47
            return ApiCFDIRest().post("cfdi/cancelar?idCfdi="+params.idCfdi+"&uidd="+params.uidd
                +"&motivoCancelacion="+params.motivo+"&uiddReemplazoCancelacion="+
                params.uiddReemplazo, {
                idCfdi: params.idCfdi,
                uidd: params.uidd,
                motivoCancelacion: params.motivo,
                uiddReemplazoCancelacion: params.uiddReemplazo
              });
        }
        catch(error){
            console.log(error);
        }
    },
    CfdiGeneradosFilter(params){
        try{
            if(params.tipo=='F'){
                return ApiDashboard().post("CfdiGeneradosFilter.htm?rfc="+params.rfc+"&fechaInicialFilter="+params.fechaInicialFilter+"&fechaFinFilter="+params.fechaFinFilter, {
                    rfc: params.rfc,
                    fechaInicialFilter:params.fechaInicialFilter,
                    fechaFinFilter:params.fechaFinFilter
                  });
            }else{
                return ApiDashboard().post("CfdiGeneradosFilter.htm?rfc="+params.rfc+"&referencia="+params.referencia, {
                    rfc: params.rfc,
                    referencia:params.referencia
                  });
            }
            
        }
        catch(error){
            console.log(error);
        }
    },
    datosFiscales(params){
        try{
            return ApiDashboard().post("DatosFiscalesForm.htm?rfc="+params.rfc, {
                rfc: params.rfc
              });
        }
        catch(error){
            console.log(error);
        }
    },
    cumplimiento(params){
        try{
            return ApiDashboard().post("CumplimientoFiscalForm.htm?rfc="+params.rfc+"&anio="+params.anio+"&mes="+params.mes, {
                rfc: params.rfc,
                anio: params.anio,
                mes: params.mes
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    ingresosEgresos(params){
        try{
            return ApiDashboard().post("ReporteIngresosEgresosForm.htm?rfc="+params.rfc+"&anio="+params.anio+"&mes="+params.mes, {
                  rfc: params.rfc,
                  anio: params.anio,
                  mes: params.mes
              });
        }
        catch(error){
            console.log(error);
        } 
    },
    downloadReporteValidacionFacturasForm(params){
        try{
            return ApiBiller().post("DownloadReporteValidacionFacturasForm.htm?rfc="+params.rfc+"&anio="+params.anio+"&mes="+params.mes, {
                  rfc: params.rfc,
                  anio: params.anio,
                  mes: params.mes
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    CfdiGeneradosForm(params){
        try{
            console.log(params);
            if(params.plataforma=="-1" || params.plataforma=="seleccionar"){
                return ApiCFDI().post("CfdiGeneradosForm.htm?emailReceptor="+params.emailReceptor
                +"&rfcReceptor="+ params.rfcReceptor
                +"&referencia="+ params.referencia, {
                rfc: params.rfc
                });
            }else{
                return ApiCFDI().post("CfdiGeneradosForm.htm?emailReceptor="+params.emailReceptor
                +"&rfcReceptor="+ params.rfcReceptor
                +"&plataforma="+params.plataforma
                +"&referencia="+ params.referencia, {
                rfc: params.rfc
                });
            }
            
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    validaCliente(params){
        try{
            return ApiDashboard().post("ValidaClienteUtaxmeForm.htm?folioUtaxme="+params.folio, {
                  folio: params.folio
              });
        }
        catch(error){
            //debugger;
            console.log(error);
        } 
    },
    catalogosSatForm(params){
        try{
            return ApiCFDI().get("CatalogosSatForm.htm", {
                rfc: ''
              });
        }
        catch(error){
            console.log(error);
        }
    },
    catalogosProductos(){
        try{
            return Api().get("utaxon/catalogs/paquetes");
        }
        catch(error){
            console.log(error);
        }
    },
    register(params){
        try{
            return Api().post("utaxon/admin/register",{
                aceptaTerminos: true,
                apellidos:'',
                email: params.email,
                folio:'',
                nombre: params.nombre,
                originId: params.originId,
                password: params.password,
                telefono: params.telefono
            });
        }
        catch(error){
            console.log(error);
        }
    },
    countCFDIS(params){
        try{
            return ApiCFDIRest().post("cfdis/clientes/count?rfc="+params.rfc+"&anio="+params.anio+"&mes="+params.mes, {
                rfc: ''
              });
        }
        catch(error){
            console.log(error);
        }
    },

    agregarConcepto(params){

        console.log(params);
    
        try{
            //return null;
            return ApiCFDIRest().post("conceptos/clientes/agregar", {
                 productoServicio: params.productoServicio,
                 identificadorSat: params.identificadorSat,
                 descripcion: params.descripcion,
                 claveUnidad: params.claveUnidad,
                 claveImpuestoSat: params.claveImpuestoSat,
                 tasa: params.tasa,//iva trasladado
                 rfc: params.rfc,
                 unidad:params.unidad,
                 valorUnitario: params.valorUnitario,
                 claveImpuestoLocal:params.claveImpuestoLocal,
                 tasaLocal: params.tasaLocal,
                 ivaRet:params.ivaRet,//0.10,//new iva retenido
                 isrRet:params.isrRet, //0.11,//new isr retenido
                 ieps:params.ieps,
                 idConceptoCliente: params.idConceptoCliente
              });
        }
        catch(error){
            console.log(error);
        }
    },
    eliminarConcepto(params){
        console.log(params);
    
        try{
            //https://www.billerticket.com/billerTicket-mobile/conceptos/clientes/eliminar?idConcepto=47
            return ApiCFDIRest().post("conceptos/clientes/eliminar?idConcepto="+params.idConcepto, {
                idConcepto: params.idConceptoCliente,
              });
        }
        catch(error){
            console.log(error);
        }
    },
    actualizarConcepto(params){

        console.log(params);
    
        try{
            return ApiCFDIRest().post("conceptos/clientes/actualizar", {
                productoServicio: params.productoServicio,
                identificadorSat: params.identificadorSat,
                descripcion: params.descripcion,
                claveUnidad: params.claveUnidad,
                claveImpuestoSat: params.claveImpuestoSat,
                tasa: params.tasa,
                rfc: params.rfc,
                 unidad:params.unidad,
                 valorUnitario: params.valorUnitario,
                 claveImpuestoLocal:params.claveImpuestoLocal,
                 tasaLocal: params.tasaLocal,
                 ivaRet:params.ivaRet,//0.10,//new iva retenido
                 isrRet:params.isrRet, //0.11,//new isr retenido
                 ieps:params.ieps,
                idConceptoCliente: params.idConceptoCliente
              });
        }
        catch(error){
            console.log(error);
        }
    },
    consultarConceptos(params){
        try{
            return ApiCFDIRest().post("conceptos/clientes/consultar", {
                rfc: params.rfc
              });
        }
        catch(error){
            console.log(error);
        }
    },
    listRegFiscalUsuario(params){
        try{
            return ApiCFDIRest().post("cfdi/regimenfiscal?rfc="+params.rfc, {
                rfc: params.rfc
              });
        }
        catch(error){
            console.log(error);
        }
    },
    catalogoProductosSAT(params){
        try{
            return ApiCFDI().post("CatalogoProductoSatForm.htm?filtro="+params.filtro, {
                filtro: params.filtro
              });
        }
        catch(error){
            console.log(error);
        }
    },
    emitir(params){
        console.log('emitir...');
        console.log(params);

        var aux = {
            usoCfdi: params.usoCfdi,
            tipoCfdi: params.tipoCfdi,
            lugarExpedicion:params.lugarExpedicion,
            formaPago:params.formaPago,
            metodoPago:params.metodoPago,
            moneda:params.moneda,
            plataformaTecnologica:params.plataformaTecnologica,
            observaciones:params.observaciones,
            bancoPago:params.bancoPago,
            cuentaBancaria:params.cuentaBancaria,
            numeroOrden: params.numeroOrden,
            condicionesPago:params.condicionesPago,
            subTotal: params.subTotal,
            total: params.total,
            emisor: params.emisor,
            receptor:params.receptor,
            informacionGlobal:params.informacionGlobal,
            conceptos:params.conceptos
          };
          console.log("enviando a cfdi....");
          console.log(JSON.stringify(aux));

        try{
            
           return ApiCFDIRest().post("cfdi/emitir/v4", {
                usoCfdi: params.usoCfdi,
                tipoCfdi: params.tipoCfdi,
                lugarExpedicion:params.lugarExpedicion,
                formaPago:params.formaPago,
                metodoPago:params.metodoPago,
                moneda:params.moneda,
                plataformaTecnologica:params.plataformaTecnologica,
                observaciones:params.observaciones,
                bancoPago:params.bancoPago,
                cuentaBancaria:params.cuentaBancaria,
                numeroOrden: params.numeroOrden,
                condicionesPago:params.condicionesPago,
                subTotal: params.subTotal,
                total: params.total,
                emisor: params.emisor,
                receptor:params.receptor,
                informacionGlobal:params.informacionGlobal,
                conceptos:params.conceptos
              });
        }
        catch(error){
            console.log(error);
        }
    },
    //Clientes frecuentes
    getClientesList(params){
        try{
            return ApiCFDIRest().post("clientes/frecuentes/list", {
                rfcCliente: params.rfcCliente
              });
        }
        catch(error){
            console.log(error);
        }
    },
    saveClientes(params){
        try{
            return ApiCFDIRest().post("clientes/frecuentes/save", {
                rfcCliente: params.rfcCliente,
                rfcReceptor: params.rfcReceptor,
                razonSocial: params.razonSocial,
                correoElectronico: params.correoElectronico,
                regimenFiscal: params.regimenFiscal,
                codigoPostal: params.codigoPostal
              });
        }
        catch(error){
            console.log(error);
        }
    },
    deleteClientes(params){
        try{
            return ApiCFDIRest().post("clientes/frecuentes/delete", {
                idEmisionServicios: params.idEmisionServicios
              });
        }
        catch(error){
            console.log(error);
        }
    },
}